<template>
  <div class='indexbox'>
    <div class="searchbox">
      <div class="left">
        <el-input class="inp" size='small' v-model="keywords" placeholder="Type Text To Search" clearable
          style="width: 200px" @change="pagechage(1)"></el-input>
        <!-- <el-button type="primary" icon="el-icon-search" circle @click="pagechage(1)">Search</el-button> -->
        <div class="inp">
          <el-select size='small' clearable v-model="assembly_status" placeholder="Order Generation Status"
            @change='pagechage(1)'>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="inp">
          <el-select size='small' clearable v-model="Availability" placeholder="Availability" @change='pagechage(1)'>
            <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="inp">
          <el-date-picker v-model="order_time" format='yyyy-MM-dd' value-format="yyyy-MM-dd" type="daterange"
            range-separator="-" start-placeholder="Loading Order Date Start" end-placeholder="End" clearable>
          </el-date-picker>
        </div>

        <el-button type="primary" :loading='btn_loadinga' @click="loadOrders">Load Unfulfilled Sales Order From DEAR
        </el-button>
      </div>
      <div class="right" style="margin-left: -5px;">


        <el-button type="primary" v-hasPermi="['aog']" :loading='btn_loadingb' @click="generate">Generate Assembly Order
        </el-button>
        <el-button type="primary" v-hasPermi="['aog']" :loading='btn_loadingc' @click="deleteorder">Delete Orders
        </el-button>
        <!-- v-hasPermi="['uas']" -->
        <el-button type="primary" :loading='btn_loadingd' @click="Updateorder">Refresh</el-button>
        <!-- v-hasPermi="['epl']" -->
        <el-button type="primary" :loading='btn_loadinge' @click="exportList">Export Picking List
        </el-button>
        <el-button type="primary" @click="getdata(); getfile()">Email Picking List</el-button>
      </div>
    </div>
    <div class="tablebox">
      <div class="draggable" v-loading='loading'>
        <el-table :data="tableData" stripe @selection-change="SelectionChange" max-height='700px' show-summary
          :summary-method="getSummaries">
          <el-table-column type="selection" fixed align='center' width='60' />
          <el-table-column prop="OrderNumber" label="SO#" width='100' align='center' fixed />
          <el-table-column prop="Status" label="Order Status" width='130' align='center' show-overflow-tooltip />
          <el-table-column prop="OrderDate" label="Order Date" sortable width='140' align='center'
            show-overflow-tooltip />
          <el-table-column prop="Customer" label="Customer" width='120' align='center' show-overflow-tooltip />
          <el-table-column prop="SalesRepresentative" label="Sales Rep" width='120' align='center'
            show-overflow-tooltip />
          <el-table-column prop="ShipBy" label="Required By Date" width='150' align='center' show-overflow-tooltip />
          <el-table-column prop="SKU" label="SKU" align='center' min-width='120' show-overflow-tooltip />
          <el-table-column prop="Name" label="Product Name" min-width='130' align='center' show-overflow-tooltip />
          <el-table-column prop="Quantity" label="Quantity" min-width='120' sortable align='center'
            show-overflow-tooltip />
          <el-table-column prop="Availability" label="Availability" min-width='160' align='center'
            show-overflow-tooltip />
          <el-table-column prop="assembly_status" label="Order Generation Status" width='190' align='center'
            show-overflow-tooltip>
            <template slot-scope="scope">
              <p>{{ scope.row.assembly_status == 0 ? 'Not Generated' : scope.row.assembly_status == 1 ? 'Generated' : ''
                }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="generated_time" label="Generated Date" align='center' min-width='140'
            show-overflow-tooltip />
        </el-table>
      </div>
      <el-pagination @current-change='pagechage' @size-change="handleSizeChange" :page-sizes="[10, 20, 50, 100]"
        :page-size="page_size" background layout="total,sizes,prev, pager, next" v-show="total > 0"
        :current-page.sync="page" :total="total" />
    </div>

    <el-dialog title="Email Picking List" :visible.sync="show" width="60%" @close="dialogClose">
      <el-form ref="form" :model="formData" :rules="rules" label-width="120px">
        <el-form-item label="Title" prop="title">
          <el-input v-model="formData.title" clearable placeholder="Enter Title"></el-input>
        </el-form-item>
        <el-form-item label="Content" prop="content">
          <el-input v-model="formData.content" type="textarea" clearable placeholder="Enter Content"></el-input>
        </el-form-item>
        <el-form-item label="To Email" prop="to_email">
          <el-input v-model="formData.to_email" clearable placeholder="Enter To Email"></el-input>
        </el-form-item>
        <el-form-item label="Attachment" prop="attachment">
          <div style="display: flex;align-items: center;"><i class="el-icon-document-checked"></i>
            <p style="margin-left: 10px;">PickingList.xls</p>
          </div>
          <el-input v-show="false" v-model="formData.attachment" clearable placeholder="Enter Attachment"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-hasPermi="['tol']" type="primary" :loading='btn_loadingf' @click="confirmEmail">Confirm</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  export default {
    data() {
      return {
        options: [
          {
            value: 1,
            label: 'Generated'
          }, {
            value: 0,
            label: 'Not Generated'
          }
        ],
        assembly_status: '',
        options2: [
          { label: 'Null', value: 'Null' },
          { label: 'in Stock - To be picked', value: 'in Stock - To be picked' },
          { label: 'Assembly to be created', value: 'Assembly to be created' },
          { label: 'Picking Incident', value: 'Picking Incident' },
          { label: 'VOIDED', value: 'VOIDED' }
        ],
        Availability: '',
        keywords: '',

        order_time: [],
        total: 0,
        page: 1,
        page_size: 10,
        tableData: [],
        loading: false,
        btn_loading: false,
        btn_loadinga: false,

        idsarr: [],
        btn_loadingb: false,
        btn_loadingc: false,
        btn_loadingd: false,
        btn_loadinge: false,
        Quantity: 0,

        show: false,
        formData: {
          title: '',
          content: '',
          to_email: '',
          attachment: '',
        },
        rules: {
          title: [
            { required: true, message: 'Please Enter Title', trigger: 'blur' }
          ],
          content: [
            { required: true, message: 'Please Enter Content', trigger: 'blur' }
          ],
          to_email: [
            { required: true, message: 'Please Enter To Email', trigger: 'blur' }
          ],
          attachment: [
            { required: true, message: 'Please Enter Attachment', trigger: 'blur' }
          ],
        },
        btn_loadingf: false,
      };
    },
    computed: {
      ...mapGetters(['api_id']),
    },
    methods: {
      filterHandler(value, row, column) {
        // const property = column['property'];
        // return row[property] === value;
        console.log(value)
      },
      getlist() {
        if (!this.$store.state.user.api_id) return;
        this.loading = true
        this.$post({
          url: "/app/saleorder_goods/pageList",
          data: {
            page: this.page,
            page_size: this.page_size,
            keywords: this.keywords,
            assembly_status: this.assembly_status,
            api_id: this.$store.state.user.api_id,
            Availability: this.Availability

          },
          success: (res) => {
            this.page = res.data.current_page
            this.page_size = Number(res.data.per_page)
            this.tableData = res.data.data
            this.total = res.data.total
            this.Quantity = res.data.Quantity
            setTimeout(() => {
              this.loading = false
            }, 200);
          },
          tip: () => {
            this.loading = false
          },
        })
      },
      pagechage(page) {
        this.page = page
        this.tableData = []
        this.getlist()
      },
      handleSizeChange(size) {
        this.page_size = size
        this.tableData = []
        this.getlist()
      },
      //Load Unfulfilled Sales Order from DEAR
      loadOrders() {
        this.btn_loadinga = true
        this.$post({
          url: "/app/saleorder/loadOrders",
          data: {
            order_start_time: this.order_time && this.order_time.length ? this.order_time[0] : '',
            order_end_time: this.order_time && this.order_time.length ? this.order_time[1] : '',
            api_id: this.$store.state.user.api_id
          },
          success: (res) => {
            this.$notify({
              title: res.msg,
              type: 'success'
            });
            setTimeout(() => {
              this.show = false
              this.btn_loadinga = false
              this.pagechage(1)
            }, 1300);
          },
          tip: () => {
            setTimeout(() => {
              this.btn_loadinga = false
            }, 1300);
          },
        })
      },
      SelectionChange(data) {
        this.idsarr = data
      },
      //Generate Assembly Order
      generate() {
        if (!this.idsarr.length) {
          this.$message({
            message: 'Please Select Assembly Order',
            type: 'warning'
          });
          return
        }
        let ids = []
        this.idsarr.map((item) => {
          ids.push(item.id)
        })
        this.btn_loadingb = true
        this.$post({
          url: "/app/saleorder_goods/generateAssemblyOrderTip",
          data: {
            api_id: this.$store.state.user.api_id,
            ids: ids.join()
          },
          success: (res) => {
            this.generateAssemblyOrder(ids.join())
          },
          showtip: (res) => {
            this.$confirm(res.msg, {
              type: 'warning'
            }).then(() => {
              this.generateAssemblyOrder(ids.join())
            }).catch(() => {
              this.btn_loadingb = false
            });
          },
          tip: () => {
            setTimeout(() => {
              this.btn_loadingb = false
            }, 1300);
          },
        })
      },
      generateAssemblyOrder(ids) {
        this.$post({
          url: "/app/saleorder_goods/generateAssemblyOrder",
          data: {
            api_id: this.$store.state.user.api_id,
            ids
          },
          success: (res) => {
            this.$notify({
              title: res.msg,
              type: 'success'
            });
            setTimeout(() => {
              this.btn_loadingb = false
            }, 1300);
            this.$nextTick(() => {
              this.pagechage(1)
            })
          },
          tip: () => {
            setTimeout(() => {
              this.btn_loadingb = false
            }, 1300);
          },
        })
      },
      // deleteSaleOrder
      deleteorder() {
        if (!this.idsarr.length) {
          this.$message({
            message: 'Please Select Assembly Order',
            type: 'warning'
          });
          return
        }
        let ids = []
        this.idsarr.map((item) => {
          ids.push(item.id)
        })
        this.btn_loadingc = true
        this.$post({
          url: "/app/saleorder/deleteSaleOrder",
          data: {
            api_id: this.$store.state.user.api_id,
            ids: ids.join()
          },
          success: (res) => {
            this.$notify({
              title: 'success',
              type: 'success'
            });
            setTimeout(() => {
              this.btn_loadingc = false
              this.pagechage(1)
            }, 1300);
          },
          tip: () => {
            setTimeout(() => {
              this.btn_loadingc = false
            }, 1300);
          },
        })
      },
      // Update Availability of Special Case
      Updateorder() {
        this.btn_loadingd = true
        this.$post({
          url: "/app/saleorder/UpdateAvailability",
          data: {
            api_id: this.api_id
          },
          success: (res) => {
            this.$notify({
              title: 'success',
              type: 'success'
            });
            setTimeout(() => {
              this.btn_loadingd = false
              this.pagechage(1)
            }, 1300);
          },
          tip: () => {
            setTimeout(() => {
              this.btn_loadingd = false
            }, 1300);
          },
        })
      },
      // Export Picking List
      exportList() {
        if (!this.idsarr.length) {
          this.$message({
            message: 'Please Select Assembly Order',
            type: 'warning'
          });
          return
        }
        let ids = []
        this.idsarr.map((item) => {
          ids.push(item.id)
        })
        let that = this
        this.btn_loadinge = true
        this.$post({
          url: "/app/digital_orders/exportPickingList",
          data: {
            api_id: this.api_id,
            ids: ids.join()
          },
          success: (res) => {
            this.$alert('Export successful, are you sure to download?', 'success', {
              confirmButtonText: 'Download',
              callback: () => {
                that.idsarr = []
                that.btn_loadinge = false
                that.copy(res.data.file_name)
                window.open(res.data.file_path)
              }
            });
          },
          tip: () => {
            setTimeout(() => {
              this.btn_loadinge = false
            }, 1300);
          },
        })
      },
      copy(data) {
        var input = document.createElement('input')
        input.value = data;
        document.body.appendChild(input)
        input.select()
        document.execCommand('copy')
        document.body.removeChild(input)
      },
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = 'Sum';
            return;
          }
          switch (column.property) {  // column.property可以匹配它的每一列的命名， 然后赋值
            case "Quantity":
              sums[index] = this.Quantity //值取自后台
              break;
            default:
              break;
          }
        });
        return sums;
      },
      dialogClose() {
        this.btn_loadingf = false
        this.formData = {
          title: '',
          content: '',
          to_email: '',
          attachment: '',
        }
        this.show = false
      },
      confirmEmail() {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.btn_loadingf = true
            this.$post({
              url: '/app/saleorder_goods/emailPickingList',
              data: {
                api_id: this.api_id,
                ...this.formData,
              },
              success: (res) => {
                this.$notify({
                  title: res.msg,
                  type: 'success'
                });
                this.btn_loadingf = false
                this.show = false
              },
              tip: () => {
                this.btn_loadingf = false
              },
            })
          }
        })
      },
      getdata() {
        this.$post({
          url: "/app/api_config/info",
          data: {
            api_id: this.api_id
          },
          success: res => {
            this.formData.title = 'PICKING LIST #';
            this.formData.content = `Dear Warehouse Team,\nPlease pick item(s)as per attached file. Thank you`;
            this.formData.to_email = res.data.to_email;
          },
          tip: () => {
          },
        })

      },
      getfile() {
        if (!this.idsarr.length) {
          this.$message({
            message: 'Please Select Assembly Order',
            type: 'warning'
          });
          return
        }
        let ids = []
        this.idsarr.map((item) => {
          ids.push(item.id)
        })
        this.loading = true
        this.$post({
          url: "/app/digital_orders/exportPickingList",
          data: {
            api_id: this.api_id,
            ids: ids.join()
          },
          success: (res) => {
            this.loading = false
            this.formData.attachment = res.data.file_name
            this.show = true;
          },
          tip: () => {
            setTimeout(() => {
              this.loading = false
            }, 1300);
          },
        })

      }
    },
    created() {
      this.getlist()
    },
  }
</script>
<style>
  .el-table-filter__bottom {
    display: flex !important;
  }
  .el-table-filter__bottom button:hover {
    color: #19cbbf;
  }
  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #19cbbf;
  }
  .el-checkbox__inner:hover {
    border-color: #19cbbf;
  }
  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #19cbbf;
  }
  .el-table th>.cell.highlight {
    color: #19cbbf;
  }
</style>
<style lang='scss' scoped>
  .indexbox {
    width: 100%;
    height: 100%;
    .searchbox {
      width: 100%;
      padding: 6px 15px;
      background: #fff;
      border-bottom: 1px solid #babdcd;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .inp {
          margin-right: 10px;
        }
      }
      .right {
        /deep/.el-date-editor {
          .el-range-input:nth-of-type(1) {
            width: 200px;
          }
          .el-range-input:nth-of-type(2) {
            width: 100px;
          }
        }
      }
      /deep/.el-button {
        background-color: #d6f3e3;
        border-radius: 10px;
        color: #3b3b3b;
        border-color: #d6f3e3;
        align-self: flex-end;
        margin: 5px;
      }
    }
    .tablebox {
      width: 100%;
      padding: 20px;
    }
    .formbox {
      /deep/.el-form-item {
        margin-bottom: 30px !important;
        .el-form-item__content {
          width: 100%;
          .el-button {
            width: 100%;
            height: 48px;
            background: $color;
            border-radius: 5px;
            border-color: $color;
          }
        }
      }
    }
    .dataselect {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    /deep/.el-dialog {
      min-width: 400px !important;
    }
  }
</style>
